import { Suspense, Fragment, useCallback, useRef, useState, useEffect } from "react";
import { extendBlockerHeroStylesheet, extendCommonButtonsStylesheet } from "@devowl-wp/web-cookie-banner";
import { useRect } from "@reach/rect";
import { useBlocker, Portal, CloseIcon, BlockerTextContainer, useBlockerStylesheet } from "../../../..";
import { HTML_ATTRIBUTE_HERO_DIALOG_DEFAULT_OPEN } from "@devowl-wp/headless-content-unblocker";
const BlockerHeroDialog = _ref => {
  let {
    blockerClassName,
    blockerOverlayId,
    heroContainerRef: ref
  } = _ref;
  const stylesheet = useBlockerStylesheet();
  const {
    HeroOverlay,
    HeroOverlayBlocker,
    A11ySkipToLink,
    screenReaderOnlyClass
  } = stylesheet.extend(...extendBlockerHeroStylesheet).extend(...extendCommonButtonsStylesheet);
  const blocker = useBlocker();
  const {
    headerDesign: {
      fontSize,
      fontColor
    },
    i18n: {
      close
    },
    blockedNode
  } = blocker;
  const refLastActiveElement = useRef();
  const [isDefaultBlockerVisible, setIsDefaultBlockerVisible] = useState(!!blockedNode?.hasAttribute(HTML_ATTRIBUTE_HERO_DIALOG_DEFAULT_OPEN));
  const rect = useRect(ref, {
    observe: isDefaultBlockerVisible
  });
  const overlayRef = useRef();

  // Automatically close on outside click
  const handleOutsideClick = useCallback(e => {
    if (isDefaultBlockerVisible && [overlayRef.current, overlayRef.current?.nextElementSibling].indexOf(e.target) > -1) {
      setIsDefaultBlockerVisible(false);
    }
  }, [isDefaultBlockerVisible, overlayRef.current]);
  const handleAcessibilityClose = e => {
    // Accessibility, hide hero container and focus previously selected element
    e.preventDefault();
    setIsDefaultBlockerVisible(false);
    refLastActiveElement.current?.focus();
  };
  useEffect(() => {
    if (ref.current) {
      const listener = e => {
        refLastActiveElement.current = document.activeElement;
        const focusSkipToConsentChoices = e.target.classList.contains(screenReaderOnlyClass);
        setIsDefaultBlockerVisible(true);
        setTimeout(() => {
          if (focusSkipToConsentChoices) {
            (overlayRef.current?.nextElementSibling.querySelector(`a[href^="#a"]`)).focus();
          }
        }, 50);
        e.preventDefault();
        e.stopImmediatePropagation();
        return false;
      };
      ref.current.addEventListener("click", listener, true);
      return () => ref.current?.removeEventListener("click", listener, true);
    }
    return () => {
      // Silence is golden.
    };
  }, [ref.current]);
  return isDefaultBlockerVisible && rect ? h(Portal, {
    renderInContainer: document.body
  }, h(Fragment, null, h(HeroOverlay, {
    ref: overlayRef,
    onClick: handleOutsideClick
  }), h(HeroOverlayBlocker, {
    id: blockerOverlayId,
    rectLeft: rect.left + window.scrollX,
    rectTop: rect.top + window.scrollY,
    rectWidth: rect.width,
    className: blockerClassName,
    onClick: handleOutsideClick
    // eslint-disable-next-line react/no-unknown-property
    ,
    "interaction-player-uqid": isDefaultBlockerVisible ? "blocker" : undefined
  }, h(A11ySkipToLink, {
    href: "#",
    onFocus: handleAcessibilityClose
  }, "\xA0"), h(Suspense, {
    fallback: null
  }, h(BlockerTextContainer, {
    closeIcon: h(CloseIcon, {
      tooltipText: close,
      width: fontSize,
      color: fontColor,
      thickness: 2,
      onClick: () => setIsDefaultBlockerVisible(false)
    })
  })), h(A11ySkipToLink, {
    href: "#",
    onFocus: handleAcessibilityClose
  }, "\xA0")))) : null;
};
export { BlockerHeroDialog };